.service {
  background-color: #F1F0EF;
}

.menu-section {
  padding: 0 1.5em;
  margin-bottom: 2em;
}

.menu-header {
  letter-spacing: 2px;
  font-family: proxima-nova;
  font-style: normal;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.4);
  font-size: 24px;
  padding: 0;
  padding-top: 40px;
}

.menu-items {
  padding-top: 40px;
}

.menu-note {
  text-align: right;
  margin-top: -20px;
  padding-bottom: 2em;
  font-style: italic;
  color: rgba(0, 0, 0, 0.7);
}

.menu-item {
  padding-bottom: 2em;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}

.item-price {
  float: right;
  font-weight: normal;
  padding-left: 10px;
}

.bottom-note {
  margin: 40px auto;
  padding: 0 1.5em;
  color: rgba(0, 0, 0, 0.7);
}

/* Screen bigger than 660px */
@media screen and (min-width : 660px) {
  .menu-items {
    width: 50%;
    max-width: 500px;
    margin: auto;
  }
}