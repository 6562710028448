.home {
  /* margin-top: -24%; */
  margin-bottom: -10px;
}

img {
  max-width: 100%;
}

@media screen and (min-width : 660px) {  
}