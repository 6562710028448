.contact {
  margin-bottom: 0;
}
.contact-section {
  padding-top: 40px;
}

.contact-container {
  padding: 0 1.5em;
  margin-bottom: 2em;
}

.contact-container p {
  padding-bottom: 10px;
  margin: 0;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 3px;
}

.contact-container img {
  height: 15px;
  margin-right: 10px; 
}

.contact-container p a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  transition: all .3s ease-in-out;
}

.contact-container p a:hover {
  color: #D2C8BB;
}

.contact-container p.title {
  color: rgba(0, 0, 0, 0.5);
}

.map {
  margin-bottom: 40px;
}

.sm-section {
  display: inline-flex;
  padding: auto;
  background: #D2C8BC;
  width: 100%;
  height: 100px;
  flex-direction: row;
  justify-content: center;
}

.sm-section a {
  margin: auto 10px;
}

.sm {
  height: 40px;
  border: none;
}

.fb {
  content: url('./photo/facebook.png');
}

.yelp {
  content: url('./photo/yelp.png');
}

.google {
  content: url('./photo/google-plus.png');
}

/* Screen bigger than 660px */
@media screen and (min-width : 660px) {
  .contact-section {
    width: 75%;
    max-width: 1800px;
    margin: auto;
  }
  .contact-container {
    padding-left: 0;
  }
}