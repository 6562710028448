html,
body {
  height: 100%;
  width: 100%;
  /* max-width: 2000px; */
}

body {
  margin: auto;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #F1F0EF;
}

h1,
h2,
h3 {
  font-family: ambroise-std, serif;
  font-weight: 400;
  color: #E6A19E;
  margin: 45px auto;
  padding: 0;
} 

h2 {
  font-size: 34px;
}

h3 {
  font-size: 30px;
}

/* hr style */
.horizontal-line {
  margin: 0;
  padding: 0;
}
.horizontal-line hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}