.header {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  min-height: auto;
  background-color: #fff;
  z-index: 1;
}

div.logo {
  content: url(./photo/TJhairlogo-name.png);
  width: 130px;
  text-align: center;
  margin: 1em auto 0 auto;
}

nav ul {
  padding: 0;
  margin: 1.3em auto;
}
 
header ul li {
  display: inline;
  padding: 0 1.5%;
  font-size: 0.8em;
  letter-spacing: 3px;
  text-transform: uppercase;
}

header a {
  position: relative;
  color: #656565;
  text-decoration: none;
}

header a:hover {
  cursor: pointer;
  color: #BB937D;
}

header a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: rgb(221, 183, 163);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

header a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}


/* Screen bigger than 660px */
@media screen and (min-width : 660px) {
  .header {
    flex-direction: row;
    
  }
  
  div.logo {
    margin: 15px 3%;
    height: 130px;
    width: auto;
  }
  
  nav {
    margin: auto 3% auto auto;
  }
   
  header ul li {
    display: inline;
    padding: 0 1.4em;
  }

}
