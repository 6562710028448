footer {
  flex-direction: column;
}

.footer-main-container {
  background-color: #464646;
  color: #DBBDAD;
  display: flex;
  flex-direction: column;
  height: auto;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 10%;
  text-align: center;
}

footer li {
  margin: 0 0 20px 0;
  font-weight: 300;
  letter-spacing: 2.6px;
}

footer .footer-title {
  font-weight: 500;
  letter-spacing: 3px;
  color: #FFFFFF;
}

.footer-rights {
  background: #1C1C1C;
  padding: 2%;
}

.footer-rights p{
  color: #6F6F6F;
  font-size: 15px;
  margin: 0;
}

/* Screen bigger than 660px */
@media screen and (min-width : 660px) {    
  footer {
    flex-direction: column;
  }

  .footer-main-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .footer-container {
    width: calc(33.333% - 20px);
  }

  footer ul {
    margin: 40% 10% 40% 15%;
    text-align: left;
  }
}

